import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loaderIcon from '../icons/loader.svg';
import Cookies from 'js-cookie';

const Verification = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);

    useEffect(() => {
        const timer = resendTimer > 0 && setInterval(() => setResendTimer(resendTimer - 1), 1000);
        return () => clearInterval(timer);
    }, [resendTimer]);

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const headers = {
            'Authorization': 'Bearer ' + Cookies.get('access_token'),
        };

        axios
            .post(window._env_.API_URL + '/api/v1/users/verify', {
                data: {
                    attributes: {
                        code: code,
                        verification_type: 'registration',
                    }
                }
            }, {
                headers: headers,
            })
            .then((response) => {
                console.log("Verified", response.data);
            })
            .catch((err) => {
                setError('Invalid verification code');
            })
            .finally(() => setLoading(false));
    };

    const handleResendCode = () => {
        if (resendTimer === 0) {
            axios.
                post(window._env_.API_URL + '/api/v1/users/verify/resend', {
                    data: {
                        attributes: {
                            verification_type: 'registration',
                        }
                    }
                });
        }
    };

    return (
        <div className='verification-container'>
            <h2 className='verification-title'>Verify Your Email</h2>
            <p className='verification-instructions'>A verification code was sent to your email. Please check your inbox and enter the code below.</p>
            {error && <p className='verification-error'>{error}</p>}

            <form className='verification-form' onSubmit={handleCodeSubmit}>
                <input
                    className='verification-input'
                    type='text'
                    placeholder='Verification Code'
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    disabled={loading}
                />

                <button className='verification-button' disabled={loading || !code}>
                    {loading ? <img src={loaderIcon} className="loader-icon" alt='' /> : 'Verify'}
                </button>

                <p className='resend-info'>
                    {resendTimer > 0 ? (
                        <span>Resend code in {resendTimer} seconds</span>
                    ) : (
                        <a className='resend-link' href='#' onClick={handleResendCode}>Resend Code</a>
                    )}
                </p>
            </form>
        </div>
    );
}

export default Verification;
