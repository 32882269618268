import { React, useState, useRef } from 'react';
import '../styles/Auth.css';
import googleLogo from '../icons/google.svg';
import eye from '../icons/eye.svg';
import eyeOff from '../icons/eyeOff.svg';
import loaderIcon from '../icons/loader.svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [icon, setIcon] = useState(eyeOff);
    const [type, setType] = useState('password');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const passwordRef = useRef(null);

    const passwordRequirements = [
        { label: 'At least 8 characters', isValid: password.length >= 8 },
        { label: 'Maximum 64 characters', isValid: password.length <= 64 },
        { label: 'Contains a number', isValid: /\d/.test(password) },
        { label: 'Contains an uppercase letter', isValid: /[A-Z]/.test(password) },
        { label: 'Contains a lowercase letter', isValid: /[a-z]/.test(password) },
        { label: 'Contains a special character', isValid: /[^A-Za-z0-9]/.test(password) },
    ];

    const axiosInstance = axios.create({
        withCredentials: true,
        baseURL: window._env_.API_URL,
    });

    const handleLoginSuccess = () => {
        const token = Cookies.get('access_token');
        if (!token) {
            setError('Something bad happened');
            console.log('Access token not found');
            return;
        }

        const decodedToken = jwtDecode(token);
        if (decodedToken.verified) {
            navigate('/dashboard');
        } else {
            navigate('/verify');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const allRequirementsMet = passwordRequirements.every(req => req.isValid);
        if (!allRequirementsMet) {
            setError('Please make sure your password meets all the requirements.');
            return;
        }

        setLoading(true);

        (isSignUp ? signUp() : signIn())
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);

                let detail = error.response.data[0].detail;
                if (detail !== undefined) {
                    let errMsg = '';
                    if (detail === 'user not found') {
                        errMsg = 'User doesn\'t exist';
                    }
                    if (detail === 'email already taken') {
                        errMsg = 'This email is already taken';
                    }

                    setError(errMsg);
                    return;
                }

                setError('Something bad happened');
                return;
            });
        
        setLoading(false);

        handleLoginSuccess();
    };

    const signUp = async () => {
        return axiosInstance
            .post('/api/v1/users/sign-up', {
                data: {
                    attributes: {
                        user_name: username,
                        email: email,
                        password: password,
                    }
                }
            });
    };

    const signIn = async () => {
        return axiosInstance
            .post('/api/v1/users/sign-in', {
                data: {
                    attributes: {
                        email: email,
                        password: password,
                    }
                }
            });
    };

    const handleGoogle = () => {
        // axios.get('https://galactiq.me/api/v1/users/auth/google')
        //     .then((res) => {
        //         console.log(res)
        //     })
        window.location.href = 'https://galactiq.me/api/v1/users/auth/google';
    };

    const handleToggleEye = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (type === 'password') {
            setIcon(eye);
            setType('text');
        } else {
            setIcon(eyeOff);
            setType('password');
        }

        setTimeout(() => {
            const length = passwordRef.current.value.length;
            passwordRef.current.focus();
            passwordRef.current.setSelectionRange(length, length);
        }, 0);
    };

    const reset = () => {
        setLoading(false);
        setIcon(eyeOff);
        setType('password');
        setError('');
    };

    return (
        <div className="auth-container">
            <div className='auth-box'>
                <h2 className='auth-title'>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>

                {error && <p className="auth-error">{error}</p>}

                <form className='auth-form' autoComplete='off' onSubmit={handleSubmit}>
                    {isSignUp && 
                        <div className="form-group">
                            <input
                                autoComplete='off'
                                className='auth-input'
                                type='text'
                                id='username'
                                placeholder='Username'
                                value={username}
                                onChange={(u) => setUsername(u.target.value)}
                                required
                                disabled={loading}
                            ></input>
                        </div>
                    }
                    <div className="form-group">
                        <input
                            autoComplete='off'
                            className='auth-input'
                            type="email"
                            id="email"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            disabled={loading}
                        ></input>
                    </div>

                    <div className="form-group">
                        <div className='password-group'>
                            <input
                                autoComplete='new-password'
                                className='auth-input'
                                type={type}
                                id="password"
                                placeholder='Password'
                                value={password}
                                onChange={(p) => setPassword(p.target.value)}
                                required
                                ref={passwordRef}
                                disabled={loading}
                            ></input>
                            <span className='toggle-password' onClick={(e) => handleToggleEye(e)}>
                                <img className='toggle-password-icon' src={icon}></img>
                            </span>
                        </div>
                    </div>

                    {password && 
                        <ul className="password-requirements">
                            {passwordRequirements.map((req, index) => (
                                !req.isValid && <li 
                                    key={index} 
                                    className={req.isValid ? 'valid' : 'invalid'}
                                >
                                    {req.label}
                                </li>
                            ))}
                        </ul>
                    }

                    {!isSignUp &&
                        <div className='auth-link'>
                            <a className='forgot-pass' href='#'>Forgot password?</a>
                        </div>
                    }

                    <button className='auth-button' disabled={loading} type="submit">
                        {loading ? <img src={loaderIcon} className="loader-icon" alt=''/> : isSignUp ? 'Sign Up' : 'Sign In'}
                    </button>

                    {!isSignUp &&
                        <div className='auth-link'>
                            <span>Don't have an account? <a onClick={() => {reset(); setIsSignUp(true)}} href='#'>Sign Up</a></span>
                        </div>
                    }
                    {isSignUp &&
                        <div className='auth-link'>
                            <span>Already have an account? <a onClick={() => {reset(); setIsSignUp(false)}} href='#'>Sign In</a></span>
                        </div>
                    }

                    <div className='auth-line'></div>

                    <div className='auth-media-options'>
                        <a href='#' className='auth-google' onClick={() => handleGoogle()}>
                            <img src={googleLogo} className="auth-media-image" alt="" />
                            <span>Sign in with Google</span> 
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Auth;
