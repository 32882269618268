import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CheckAuth from './components/CheckAuth';
import Auth from './components/Auth';
import Verification from './components/Verification';
import { React } from 'react';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<CheckAuth/>} />
                <Route path='/login' element={<Auth/>} />
                <Route path='/verify' element={<Verification/>} />
            </Routes>
        </Router>
    );
}

export default App;
